<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">
        <app-i18n code="salesRequests.title"></app-i18n>
      </h2>
    </div>
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    >
    </TableFilter>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="overflow-x-auto scrollbar-hidden">
        <div ref="table" class="relative">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#000"
            :loading="loading"
          />
          <div class="overflow-x-auto">
            <table
              class="table table-report sm:mt-2"
              id="salesRequestsTable"
              ref="tableRef"
            >
              <thead>
                <tr>
                  <th class="whitespace-nowrap w-10" id="ignore-6">#</th>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="col.name === 'action' ? 'ignore-1' : ''"
                    class="text-center whitespace-nowrap w-20"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                  <td class="w-10">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ rowIndex(index) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap cursor-pointer">
                      {{ presenter(row, 'fullName') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      <a :href="`tel:${row.phoneNumber}`">{{ presenter(row, 'phoneNumber') }}</a>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{
                        presenterLocalization(row.unitTemplate.project, 'name')
                      }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterLocalization(row.unitTemplate, 'title') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'createdAt') }}
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex justify-center items-center">
                      <a
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#delete-modal-preview"
                        @click="selectedId = row.id"
                        class="text-theme-24 flex items-center gap-1 whitespace-nowrap"
                      >
                        <Trash2Icon class="w-4 h-4" />
                        {{ i18n('common.delete') }}
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.lead')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <Pagination
            v-if="rows.length"
            :page="currentPage"
            :pageSize="pagination?.limit"
            :rowsNumber="pagination?.total"
            :showSizeChanger="true"
            :disabled="!rows.length"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
          ></Pagination>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- BEGIN: Modal -->
    <DeleteModal item="lead" @delete="doDestroy(selectedId)" />
    <!-- END: Modal -->
  </div>
</template>

<script>
import DeleteModal from '@/components/modals/delete-modal.vue'
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  components: {
    DeleteModal
  },
  data() {
    return {
      selectedRow: null,
      selectedId: null,
      filterInput: [],
      visibleModal: false,
      columns: [
        {
          name: 'clientName',
          field: 'data',
          label: 'salesRequests.fields.clientName',
          align: 'center'
        },
        {
          name: 'clientPhone',
          field: 'data',
          label: 'salesRequests.fields.clientPhone',
          align: 'center'
          // required: true,
        },
        {
          name: 'projectTitle',
          field: 'data',
          label: 'salesRequests.fields.projectTitle',
          align: 'center'
        },
        {
          name: 'unitTitle',
          field: 'data',
          label: 'salesRequests.fields.unitTitle',
          align: 'center'
          // required: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'salesRequests.fields.createdAt',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'lead/list/rows',
      pagination: 'lead/list/pagination',
      loading: 'lead/list/loading'
    }),
    currentPage() {
      return this.pagination?.currentPage || 1
    },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
        // return index
      }
    },
    filterFields() {
      return [
        {
          label: this.i18n('iam.filter.clientName'),
          value: 'fullName',
          type: 'text'
        },
        {
          label: this.i18n('iam.filter.phoneNumber'),
          value: 'phoneNumber',
          type: 'text'
        },
        {
          label: this.i18n('iam.filter.project'),
          value: 'projectId',
          type: 'text'
        },
        {
          label: this.i18n('iam.filter.unit'),
          value: 'unitTemplateId',
          type: 'text'
        }
      ]
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.salesRequests')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.salesRequests')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'lead/list/doFetch',
      doFetchNextPage: 'lead/list/doFetchNextPage',
      doFetchPreviousPage: 'lead/list/doFetchPreviousPage',
      doChangePaginationCurrentPage: 'lead/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'lead/list/doChangePaginationPageSize',
      doDestroy: 'lead/list/doDestroy'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    doOpenModal(row) {
      this.selectedRow = row
      this.visibleModal = true
      // this.showModal()
    },
    doCloseModal() {
      // this.hideModal()
      this.selectedRow = null
      this.visibleModal = false
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    async doFilter(filter, orderBy) {
      this.filterInput = []
      if (!filter || !filter.value) return
      if (Array.isArray(filter)) {
        this.filterInput.push(...filter)
      } else {
        this.filterInput.push(filter)
      }
      await this.doFetchFirstPage(orderBy)
    },
    async doResetFilter() {
      this.filterInput = []
      await this.doFetchFirstPage()
    },
    async doFetchFirstPage(orderBy) {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: this.filterInput,
        pagination: {
          firstPage: true,
          limit: 10,
          action: 'next',
          orderBy: orderBy || 'createdAt',
          sortType: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    }
  }
}
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
